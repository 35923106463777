
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { ApiView } from '../../../../components/apiView';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import NestedDialogs from './examples/NestedDialogsExample.vue';
import NestedDialogsCode from '!!raw-loader!./examples/NestedDialogsExample.vue';
import Simple from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';
import { apis } from './apis';

export default defineComponent({
	name: 'dialog-documentation',
	components: {
		ExampleView,
		ApiView,
		Simple,
		NestedDialogs,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			NestedDialogsCode,
			SimpleCode
		};
	}
});
