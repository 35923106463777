
import { BButton, BDialog, BTitle } from 'buetify/lib/components';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'nested-dialog-example',
	components: {
		BButton,
		BDialog,
		BTitle
	}
});
