
import { BButton, BTitle, BDialog, BField, BInput } from 'buetify/lib/components';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'dialog-example',
	components: {
		BButton,
		BDialog,
		BTitle,
		BField,
		BInput
	}
});
